<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-4>
          <v-layout row justify-space-between align-center>
            <v-flex xs12 sm4 md4 lg5>
              <span class="heading">Victim Details</span>
            </v-flex>
            <v-flex xs12 sm4 md5 lg5 pt-2 pt-lg-0 pt-md-0 pt-sm-0>
              
              <v-text-field class="text-des "
                outlined
                hide-details
                dense
                placeholder="Type To Search"
                v-model="keyword"
              >
              </v-text-field>
            </v-flex>

            <v-flex  xs12 sm3 md2 lg2 pt-2 pt-lg-0 pt-md-0 pt-sm-0  class="text-right" v-if="mainRole === 'MedicalOfficer'">
              <v-btn color="#427D2D" @click="adddialog = true">
                <span style="
                        font-size: 12px;
                        font-family: poppinssemibold;
                        color: white;
                      ">
                  Add Details
                  <v-icon color="white">mdi-plus</v-icon>
                </span>
              </v-btn>
            </v-flex>
            

          </v-layout>

          <v-flex xs12 v-if="userlist && userlist.length > 0" py-4>
           
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left tablehead">Name</th>
                         <th class="text-left tablehead">Phone</th>
                         <th class="text-left tablehead">District</th>
                        <th class="text-left tablehead">Dosage</th>
                        <th class="text-left tablehead">Snake</th>
                        <th class="text-left tablehead">Venom Type</th>
                        <th class="text-left tablehead">Health Condition</th>

                        <th class="text-left tablehead" v-if="mainRole === 'MedicalOfficer'">Edit</th>

                        <th class="text-left tablehead" v-if="mainRole === 'MedicalOfficer'">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="cursor: pointer"  v-for="(item, index) in userlist" :key="index" @click="openDetailsDialog(item)">
                        <td style="white-space: nowrap;" class="tableitems">{{ item.name }}</td>
                      
                        <td style="white-space: nowrap;" class="tableitems">{{ item.mobileno }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.Districtname }}</td>
                        
                        <td style="white-space: nowrap;" class="tableitems">{{ item.dosage }} ({{ item.unit }})</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.snakeName }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.venomType }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.healthCondition.slice(0, 30) }}</td>



                        <td style="white-space: nowrap;" class=" action-column" v-if="mainRole === 'MedicalOfficer'">
                         
                          
                          <v-icon
                          small
                          color="primary"
                          class="ml-2"
                          @click.stop="editSlider(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        </td>

                        <td style="white-space: nowrap;" v-if="mainRole === 'MedicalOfficer'">
                          
                            <v-icon
                            small
                            color="error"
                            class="ml-4"
                            @click.stop="rejectDialog(item._id)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pt-16 v-else>
            <v-layout wrap justify-center align-center>
              <v-flex xs12 class="text-center">
                <span class="nodata">No Data Found !</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="pages > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination :length="pages" v-model="currentPage" color="#427D2D" circle></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>


    </v-layout>
    <!-- disply other details dialog -->
    <v-dialog v-model="detailsDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span style="font-family:sofiaProSemiBold">Additional Details of {{ selectedItem.name }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div>
            <span class="additionaldeshead">Age: </span> <span class="tableitems" >{{ selectedItem.age }}</span><br /> 
            <span class="additionaldeshead">Gender: </span> <span class="tableitems" >{{ selectedItem.gender }}</span><br />
            <span class="additionaldeshead">Admit Date & Time: </span>
            <span class="tableitems">{{ formattedDate1(selectedItem.admitDate) }}, {{ formattedTime(selectedItem.admitTime) }}</span><br />
            <span class="additionaldeshead">Health Condition:  </span> <span class="tableitems" >{{ selectedItem.healthCondition }}</span><br />
            <span class="additionaldeshead">Address: </span> <span class="tableitems" >{{ selectedItem.address }}</span><br />
            <span class="additionaldeshead">District: </span> <span class="tableitems" >{{ selectedItem.Districtname }}</span><br />

          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="#427D2D" @click="detailsDialog = false"><span style="color:white;"> Close</span> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="adddialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">ADD VICTIM DETAILS</span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="adddialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start pt-2>

              <v-flex xs12 sm12 md12 lg12 pr-2 class="subheading">
                <span class="label" >Name</span>
                <v-text-field class="pt-2 text-des" outlined v-model="name" dense hide-details>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Age</span>
                <v-text-field type="number" class="pt-2 text-des" outlined v-model="age" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Gender</span>
                <!-- <v-text-field  class="pt-2 text-des" outlined v-model="gender" dense hide-details>
                                </v-text-field> -->
                <v-select :items="genderArray" class="pt-2 text-des" outlined dense hide-details item-text="name"
                  item-value="name" v-model="gender">
                </v-select>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Phone Number</span>
                <v-text-field type="number" class="pt-2 text-des" outlined v-model="mobileno" dense hide-details   :rules="[v => (v && v >= 0) || 'Invalid mobile number']">
                </v-text-field>
              </v-flex>


              <v-flex xs12 sm12 md6 lg6 pr-2 pt-2 class="subheading">
                <span class="label">Select Snake</span>
                <v-select :items="snakes" class="pt-2 text-des" outlined dense hide-details item-text="name"
                  item-value="_id" v-model="snake">
                </v-select>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Select Venom Type</span>
                <v-select :items="venom" class="pt-2 text-des" item-text="name"
                item-value="name" outlined dense hide-details v-model="venomtype">
                </v-select>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Dosage (ml)</span>
                <v-text-field type="number" class="pt-2 text-des" outlined v-model="dosage" dense hide-details>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Admit Date</span>
                <v-text-field class="pt-2 text-des" outlined dense hide-details :value="formattedDate"
                  @click="admitdatedialog = true" readonly></v-text-field>

                <v-dialog v-model="admitdatedialog" max-width="290">
                  <v-date-picker color="#427D2D" v-model="admitDate" @input="updateDate"></v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Admit Time</span>
                <v-dialog ref="dialog" v-model="modal2" :return-value.sync="admitTime" persistent width="290px">

                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-2 text-des" v-model="admitTime" outlined dense hide-details readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker color="#427D2D" v-if="modal2" v-model="admitTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="#427D2D" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#427D2D" @click="$refs.dialog.save(admitTime)">
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2 class="subheading">
                <span class="label">Choose District</span>
                <v-select  class="pt-2 text-des"
                    outlined
                    hide-details
                    dense
                    :items="districts"
                    v-model="Districtname"
                    placeholder="Select District"
                    style="font-family: sofiaProRegular;"
                  >
                  </v-select>
              </v-flex>

              <v-flex xs12 sm12 md6 lg12 pt-2 class="subheading">
                <span class="label">Address</span>
                <v-textarea class="pt-2 text-des" outlined v-model="address" dense hide-details>
                </v-textarea>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2 class="subheading">
                <span class="label">Health Condition</span>
                <v-textarea class="pt-2 text-des" outlined v-model="healthCondition" dense hide-details>
                </v-textarea>
              </v-flex>




            </v-layout>
          </v-form>
        </v-card-text>



        <v-card-actions class="py-4 justify-end headline  lighten-2">
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed class="itemValue" text
            @click="adddialog = false"><span style="color: black;">Cancel</span> </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed class="itemValue" @click="itemadd()"><span
              style="color:white;">Save Changes</span> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">EDIT VICTIM DETAILS </span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start pt-2>

              <v-flex xs12 sm12 md12 lg12  class="subheading">
                <span class="label">Name</span>
                <v-text-field class="pt-2 text-des" outlined v-model="editingitem.name" dense hide-details>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 pr-2 pt-2 class="subheading">
                <span class="label">Age</span>
                <v-text-field type="number" class="pt-2 text-des" outlined v-model="editingitem.age" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 pt-2 class="subheading">
                <span class="label">Gender</span>
                <v-text-field class="pt-2 text-des" outlined v-model="editingitem.gender" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Phone Number</span>
                <v-text-field type="number" class="pt-2 text-des" outlined v-model="editingitem.mobileno" dense
                  hide-details>
                </v-text-field>
              </v-flex>


              <v-flex xs12 sm12 md6 lg6 pt-2 class="subheading">
                <span class="label">Select Snake</span>
                <v-select :items="snakes" class="pt-2 text-des" outlined dense hide-details item-text="name"
                  item-value="name" v-model="editingitem.snakeName">
                </v-select>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Dosage (ml)</span>
                <v-text-field type="number" class="pt-2 text-des" outlined v-model="editingitem.dosage" dense hide-details>
                </v-text-field>
              </v-flex>


              <v-flex xs12 sm12 md6 lg6 pt-2 class="subheading">
                <span class="label">Venom Type</span>
                <v-select :items="venom" class="pt-2 text-des" item-text="name"
                item-value="name" outlined dense hide-details
                  v-model="editingitem.venomType">
                </v-select>
              </v-flex>


              <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                <span class="label">Admit Date</span>
                <v-menu ref="menu" class="pt-2 text-des" style="font-size:14px" v-model="menu"
                  :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-2 text-des" v-model="editadmitDate" outlined dense hide-details
                      v-bind="attrs" v-on="on" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="editadmitDate" @input="updateEditDate" locale="en"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 pt-2 class="subheading">
                <span class="label">Admit Time</span>
                <v-dialog ref="dialog" v-model="modal2" :return-value.sync="editingitem.admitTime" persistent
                  width="290px">

                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-2 text-des" v-model="editingitem.admitTime" outlined dense hide-details
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker color="#427D2D" v-if="modal2" v-model="admitTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="#427D2D" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#427D2D" @click="$refs.dialog.save(admitTime)">
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-2 class="subheading">
                <span class="label">Choose District</span>
                <v-select
                    outlined class="pt-2 text-des"
                    hide-details
                    dense
                    :items="districts"
                    v-model="editingitem.Districtname"
                    placeholder="Select District"
                    style="font-family: sofiaProRegular;"
                  >
                  </v-select>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2 class="subheading">
                <span class="label">Address</span>
                <v-textarea class="pt-2 text-des" outlined v-model="editingitem.address" dense hide-details>
                </v-textarea>
              </v-flex>


              <v-flex xs12 sm12 md12 lg12 pt-2 class="subheading">
                <span class="label">Health Condition</span>
                <v-textarea class="pt-2 text-des" outlined v-model="editingitem.healthCondition" dense hide-details>
                </v-textarea>
              </v-flex>




            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions class="py-4 justify-end headline lighten-2">
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed class="itemValue"
            @click="closeDialog()"><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed class="itemValue"
            @click="edit(editingitem._id)"><span style="color: white">Save Changes</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Delete Patient</v-card-title>
        <v-card-text>
          Are you sure you want to delete this patient?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed @click="deleteDialog = false"
            class="itemValue">
            Cancel
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed @click="deleteUser()" class="itemValue">
            <span style="color: #fff">Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      detailsDialog: false,  // Controls visibility of the dialog
      selectedItem: {},  // Selected item to display in the dialog
      admitdatedialog: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 10,
      keyword: "",
      name: "",
      venomtype: "",
      address: "",
      age: "",
      editdialog: false,
      editingitem: [],
      editDate: '',
      gender: "",
      dosage: "",
      healthCondition: "",
      adddialog: false,
      userlist: [],
      Districtname: "",
      snake: "",
      mobileno: "",
      admitDate: "",
      menu: false,
      genderArray: [
        "Male", "Female", "Other"
      ],
      snakes: [],
      venom: [],
      districts: [
        "All",
        "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
      ],
      deleteDialog: false,
      admitTime: null,

      modal2: false,
    };
  },
  beforeMount() {
    this.getData();
    this.getSnake();
    this.getVenom();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
        this.getData();
        this.currentPage=1
      },
   
    editingitem: {
      deep: true,
      handler() {
        if (this.editingitem && this.editingitem.admitDate) {
          this.editDate = this.formatDate(new Date(this.editingitem.admitDate));
        }
      }
    }
  },
  computed: {
    mainRole() {
     return this.$store.state.userRole;
   },
    formattedDate() {
      // Only format and return the date if one is selected
      return this.admitDate ? new Date(this.admitDate).toLocaleDateString() : "";
    },
    editadmitDate: {
      get() {
        // Format date as 'YYYY-MM-DD'
        return this.editDate ? this.formatDate(this.editDate) : '';
      },
      set(newValue) {
        // Convert string to Date object and update
        this.editDate = newValue;
        this.editingitem.admitDate = this.parseDate(newValue);
      }
    },
    // nowDate() {
    //   const now = new Date();
    //   return now.toISOString().substr(0, 10);
    // },
  },
  methods: {
    formattedDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB'); // 'en-GB' formats to dd/mm/yyyy
    },
    
    // Function to format time as hh:mm AM/PM
    formattedTime(timeString) {
      const time = new Date(`1970-01-01T${timeString}:00`);
      return time.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    },
    openDetailsDialog(item) {
      this.selectedItem = item;  // Set the selected item data
      this.detailsDialog = true;  // Open the dialog
    },
    updateDate(admitDate) {
      this.admitDate = admitDate;
      this.admitdatedialog = false; // Close the dialog after selecting the date
    },
    updateEditDate(date) {
      this.menu = false; // Close the menu after selecting the date
      this.editDate = date;
      this.editingitem.admitDate = this.parseDate(date);
    },
    formatDate(date) {
      if (!date) return '';
      // Ensure date is formatted to 'YYYY-MM-DD'
      return date instanceof Date ? date.toISOString().slice(0, 10) : date;
    },
    parseDate(dateString) {
      // Convert 'YYYY-MM-DD' format to a Date object
      return new Date(dateString).toISOString();
    },
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["id"] = this.editingitem._id;
      user["name"] = this.editingitem.name;
      user["address"] = this.editingitem.address;
      user["age"] = this.editingitem.age;
      user["gender"] = this.editingitem.gender;
      user["healthCondition"] = this.editingitem.healthCondition;
      user["snake"] = this.editingitem.snake;
      user["dosage"] = this.editingitem.dosage;
      user["venomType"] = this.editingitem.venomType;
      user["admitDate"] = this.editingitem.admitDate;
      user["admitTime"] = this.editingitem.admitTime;
      user["Districtname"] = this.editingitem.Districtname;
      user["mobileno"] = this.editingitem.mobileno;
      axios({
        url: "/edit/victimdetails",
        method: "POST",
        data: user,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;

            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // openviewdetails(item) {
    //   this.$router.push({
    //     path: "/rescuerslistview",
    //     query: {
    //       id: item._id,
    //     },
    //   });
    // },
    // getExcel() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/rescuer/getlist/download/excel",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     responseType: "blob",
    //     params: {
    //       keyword: this.keyword,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       const url = URL.createObjectURL(
    //         new Blob([response.data], {
    //           type: "application/vnd.ms-excel",
    //         })
    //       );
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "file.xlsx");
    //       document.body.appendChild(link);
    //       link.click();
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    rejectDialog(item) {
      this.deleteDialog = true;
      this.deleteid = item;
    },
    // openeditdetails(item) {
    //   this.$router.push({
    //     path: "/newrescuerslistedit",
    //     query: {
    //       id: item.user._id,
    //     },
    //   });
    // },
    deleteUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/delete/victimdetails",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    itemadd() {

      if (!this.name) {
        this.msg = "Please Provide Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.age) {
        this.msg = "Please Provide age.";
        this.showSnackBar = true;
        return;
      }
      if (!this.gender) {
        this.msg = "Please Provide Gender.";
        this.showSnackBar = true;
        return;
      }
      if (!this.mobileno) {
    this.msg = "Please provide mobile number.";
    this.showSnackBar = true;
    return;
  }

  // Phone number validation - check if it's exactly 10 digits
  if (!/^\d{10}$/.test(this.mobileno)) {
    this.msg = "Please provide a valid 10-digit mobile number.";
    this.showSnackBar = true;
    return;
  }
      if (!this.snake) {
        this.msg = "Please select Snake.";
        this.showSnackBar = true;
        return;
      }
      if (!this.venomtype) {
        this.msg = "Please select venom type.";
        this.showSnackBar = true;
        return;
      }
     
      if (!this.dosage) {
        this.msg = "Please provide dosage.";
        this.showSnackBar = true;
        return;
      }
      if (!this.admitDate) {
        this.msg = "Please select date.";
        this.showSnackBar = true;
        return;
      }
      if (!this.admitTime) {
        this.msg = "Please select time.";
        this.showSnackBar = true;
        return;
      }
      if (!this.Districtname) {
        this.msg = "Please select district.";
        this.showSnackBar = true;
        return;
      }

      if (!this.address) {
        this.msg = "Please provide Address.";
        this.showSnackBar = true;
        return;
      }

      if (!this.healthCondition) {
        this.msg = "Please provide health Condition.";
        this.showSnackBar = true;
        return;
      }



      this.appLoading = true;
      axios({
        method: "POST",
        url: "/add/victimdetails",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {

          name: this.name,
          address: this.address,
          gender: this.gender,
          age: this.age,
          healthCondition: this.healthCondition,
          venomType: this.venomtype,
          snake: this.snake,
          dosage: this.dosage,
          mobileno: this.mobileno,
          admitDate: this.admitDate,
          admitTime: this.admitTime,
          Districtname:this.Districtname


        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.adddialog = false;

            this.name = null,
              this.address = null,
              this.gender = null,
              this.age = null,
              this.healthCondition = null,
              this.venomtype = null,
              this.snake = null,
              this.dosage = null,
              this.mobileno = null,
              this.admitDate = null,
              this.admitTime = null,
              this.district=null

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/victimdetails/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          keyword: this.keyword,
        
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.userlist = response.data.data;
              this.pages = Math.ceil(response.data.totalLength / this.limit);
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },


    getVenom() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/list/venoms",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.venom = response.data.data;
                //this.pages = Math.ceil(response.data.totalLength / this.limit);
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      


    getSnake() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snake/all/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   keyword: this.keyword,
        //   count: this.count,
        //   page: this.currentPage,
        // },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.snakes = response.data.data;
              //this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}

.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>